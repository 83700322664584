.button-main {
  border-radius: 0;
  border: 6px solid #e48423;
  color: #402d1d;
  background-color: #f2e1bc;
  font-family: "VT323", monospace;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-right: 4.5px;
  margin-bottom: 4.5px;
}

.button-container {
  position: relative;
  width: fit-content;
  height: fit-content;
  margin: 2rem;
}

.button-main {
  position: relative;
}

.button-main h1 {
  margin: 0;
}

.button-shadow {
  position: absolute;
  box-sizing: border-box;
  border: 4.5px solid #e48423;
  top: 4.5px;
  left: 4.5px;
  width: calc(100% - 4.5px);
  height: calc(100% - 4.5px);
  transition: all 0.3s ease-in-out;
}

.button-main:hover {
  margin-top: 4.5px;
  margin-left: 4.5px;
  margin-bottom: 0px;
  margin-right: 0px;
}

.button-danger > .button-main {
  background-color: #fc3400;
  border: 6px solid #fc3400;
}

.button-danger > .button-shadow {
  background-color: #cc0000;
}
